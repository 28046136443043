const isDevelopment = process.env.NODE_ENV === 'development';

// URLs base
export const API_URL = isDevelopment 
  ? 'http://localhost:5000/api'
  : process.env.REACT_APP_API_URL?.split(',')[0] || 'https://misrravb.com/api';

export const UPLOADS_URL = isDevelopment
  ? 'http://localhost:5000/uploads'
  : 'https://misrravb.com/uploads';

// Configuración de timeouts
export const API_TIMEOUT = 10000; // 10 segundos

// Configuración de caché
export const CACHE_DURATION = {
  RANKING: 900, // 15 minutos
  ACTIVITY: 0, // Sin caché para actividad
  AVATAR: 60 // 1 minuto
};

// Configuración de búsqueda
export const SEARCH_CONFIG = {
  MIN_CHARS: 2,
  DEBOUNCE_TIME: 300, // 300ms
  MAX_RESULTS: 10
};

// Configuración de polling
export const POLLING_INTERVAL = {
  ACTIVITY: 2000, // 2 segundos
  RANKING: 60000 // 1 minuto
};
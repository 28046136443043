import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { API_URL, UPLOADS_URL } from '../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import debounce from 'lodash/debounce';

const PublicAvatarSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);

  // Asegurar que usamos HTTPS en producción
  const getSecureApiUrl = () => {
    if (process.env.NODE_ENV === 'development') {
      return API_URL;
    }
    // Usar la primera URL del .env o fallback a misrravb.com
    const apiUrl = process.env.REACT_APP_API_URL?.split(',')[0] || 'https://misrravb.com/api';
    return apiUrl.startsWith('https://') ? apiUrl : `https://${apiUrl.replace('http://', '')}`;
  };

  // Debounce la función de búsqueda para evitar muchas llamadas al servidor
  const debouncedSearch = useCallback(
    debounce(async (value) => {
      if (value.length < 2) {
        setSearchResults([]);
        setShowResults(false);
        return;
      }

      try {
        setLoading(true);
        const secureApiUrl = getSecureApiUrl();
        const response = await fetch(`${secureApiUrl}/avatars/search?search=${encodeURIComponent(value)}`);
        
        if (!response.ok) {
          throw new Error('Error en la búsqueda');
        }
        
        const data = await response.json();
        setSearchResults(data);
        setShowResults(true);
      } catch (error) {
        console.error('Error:', error);
        setSearchResults([]);
      } finally {
        setLoading(false);
      }
    }, 500),
    []
  );

  const handleSearch = (value) => {
    setSearchTerm(value);
    debouncedSearch(value);
  };

  return (
    <div className="relative">
      {/* Resultados de búsqueda - Ahora aparecen arriba */}
      {showResults && (searchResults.length > 0 || loading) && (
        <div className="absolute bottom-full mb-2 left-0 right-0 z-50 bg-black/90 backdrop-blur-sm rounded-lg border border-purple-500/30 shadow-2xl overflow-hidden">
          {loading ? (
            <div className="p-4 text-center text-gray-400">
              <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-500 mx-auto"></div>
            </div>
          ) : (
            <div className="max-h-[300px] overflow-y-auto">
              {searchResults.map((result) => (
                <Link
                  key={result._id}
                  to={`/misrraelita/${result.twitchUsername}`}
                  className="flex items-center p-3 hover:bg-purple-900/30 transition-colors duration-200 border-b border-purple-500/20 last:border-b-0"
                  onClick={() => setShowResults(false)}
                >
                  <div className="w-10 h-10 bg-gray-800 rounded-lg overflow-hidden flex-shrink-0">
                    <img
                      src={`${UPLOADS_URL}/saved-avatars/${result.avatarImage}`}
                      alt={result.twitchUsername}
                      className="w-full h-full object-contain"
                    />
                  </div>
                  <div className="ml-3 flex-1">
                    <div className="text-white font-medium">{result.twitchUsername}</div>
                    {result.ranking && (
                      <div className="text-sm text-gray-400">
                        Ranking: #{result.ranking}
                      </div>
                    )}
                  </div>
                  {result.points > 0 && (
                    <div className="text-purple-400 font-bold ml-2">
                      {result.points}pts
                    </div>
                  )}
                </Link>
              ))}
            </div>
          )}
        </div>
      )}

      {/* Barra de búsqueda */}
      <div className="relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Buscar avatar por usuario..."
          className="w-full px-4 py-3 bg-black/50 text-white rounded-lg border border-purple-500/30 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent pl-10"
        />
        <FontAwesomeIcon 
          icon={faSearch} 
          className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
        />
      </div>
    </div>
  );
};

export default PublicAvatarSearch;